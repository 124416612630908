import instanceApi from '@/api'
import Enterprise from '../models/enterprise';
const state = {};

const getters = {}

const mutations = {}

const actions = {
    getAllEnterpriseUsers() {
        instanceApi.get('/companie')
            .then((response) => {
                const enterprises = response.data.data
                enterprises.forEach(user => {
                    Enterprise.insert({ data: user })
                });
            })
            .catch(error => {
                console.log(error);
            })
    },

    getPaginateCompanie (_, params) {
        return new Promise((resolve, reject) => {
          instanceApi.get('/companie/paginate', {params})
          .then((response) => {
              console.log('enterprises ', response);
              const enterprises = response.data.data
              enterprises.forEach(user => {
                  Enterprise.insert({data: user})
              });
              resolve(response.data)
          })
          .catch(error => {
              console.log('getAllAdmin ', error);
              reject(error.statusText)
          })
      })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true

}