import Vue from 'vue';
import Vuex from 'vuex';
import VuexOrm from "@vuex-orm/core";
import User from './models/user';
Vue.use(Vuex);

import users from './modules/users'
import axios from 'axios';
import Particular from './models/particular';
import particulars from './modules/particulars';
import Enterprise from './models/enterprise';
import enterprises from './modules/enterprises';
import admin from './modules/admin';
import auth from './modules/auth';
import activity from './modules/activity';

const database = new VuexOrm.Database();

database.register(User, users);
database.register(Particular, particulars);
database.register(Enterprise, enterprises);

export default new Vuex.Store({
    plugins: [VuexOrm.install(database)],

    state: {
        stats: null
    },
    getters: {
        getStatsData(state) {
            return state.stats
        }
    },
    mutations: {},
    actions: {
        getStats({ state }) {
            axios.get('/statistics')
                .then((res) => {
                    console.log(res);
                    state.stats = res.data
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },
    modules: {
        users,
        admin,
        auth,
        enterprises,
        particulars,
        activity
    }
})