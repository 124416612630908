/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrowPagePrev': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 6.71c.39.39.39 1.02 0 1.41L11.12 12 15 15.88a.996.996 0 11-1.41 1.41L9 12.7a.996.996 0 010-1.41l4.59-4.59c.38-.38 1.02-.38 1.41.01z" _fill="#BAC7D5"/>'
  }
})
