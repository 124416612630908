/* eslint-disable */
require('./achievement')
require('./addrIcon')
require('./allEnterprise')
require('./allParticular')
require('./arrowNext')
require('./arrowPageNext')
require('./arrowPagePrev')
require('./arrowPrev')
require('./avatar')
require('./backArrow')
require('./bni')
require('./calendar')
require('./checkStep')
require('./chevronPrev')
require('./closeMod')
require('./collectCheck')
require('./copy')
require('./enterprise')
require('./error')
require('./eye')
require('./eyeOff')
require('./home')
require('./infoCircle')
require('./logo')
require('./mail')
require('./mailIcon')
require('./menu')
require('./online')
require('./particular')
require('./phoneIcon')
require('./pwd')
require('./registerSuccess')
require('./response')
require('./selectArrow')
require('./uploadId')
require('./user')
require('./userIcon')
