/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'allParticular': {
    width: 48,
    height: 49,
    viewBox: '0 0 48 49',
    data: '<rect pid="0" y=".5" width="48" height="48" rx="24" _fill="#EEFBF4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M29.333 19.167A5.332 5.332 0 0124 24.5a5.332 5.332 0 01-5.333-5.333A5.332 5.332 0 0124 13.833a5.332 5.332 0 015.333 5.334zm-16 13.333c0-3.547 7.107-5.333 10.667-5.333 3.56 0 10.667 1.786 10.667 5.333v1.334c0 .733-.6 1.333-1.334 1.333H14.667c-.734 0-1.334-.6-1.334-1.334V32.5z" _fill="#27AE60"/>'
  }
})
