/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M8.217 12.592a.312.312 0 01.22-.092h3.126a.313.313 0 01.312.313v5.312a.625.625 0 00.625.625h3.752a1.25 1.25 0 001.25-1.25v-6.448a.311.311 0 00-.025-.123m-9.26 1.663l-.354-.354A.813.813 0 018.437 12h3.126a.813.813 0 01.812.813v5.312a.125.125 0 00.125.125h3.752a.75.75 0 00.75-.75v-6.368m-8.785 1.46a.313.313 0 00-.092.22v5.313a.625.625 0 01-.625.625H3.746a1.25 1.25 0 01-1.25-1.25m5.72-4.908l-.353-.354a.813.813 0 00-.238.575v5.312a.125.125 0 01-.125.125H3.746a.75.75 0 01-.75-.75h-.5m14.506-6.368l.058.056m-.058-.056v-.08c0 .026.006.05.015.074l.172-.074h0L9.873 4.318h0m7.13 6.814l-7-6.69m7.058 6.745h0l.13-.135h0l.287-.123m-.417.258a.187.187 0 01-.043-.061l.172-.074h0l-.129.136zm.417-.258l-.287.123-7.405-7.096a.313.313 0 01.432 0l7.189 6.87c.03.03.055.065.071.103zm-7.476-6.488l.13-.123a.187.187 0 01-.26 0m.13.123l-7.004 6.691m7.004-6.69l-.13-.124m0 0l-6.874 6.814m0 0v-.08c0 .026-.005.05-.015.074l-.171-.073.171.073a.187.187 0 01-.043.062m.058-.056l-.187-.08h0l.13.136m.057-.056l-.058.056m.058-.056V17.5h-.5m.442-6.313h0l-.13-.134h0v-.001l-.287-.123.287.123-.313 6.448" _fill="currentColor" _stroke="currentColor"/><path pid="1" d="M10.563 2.345a.812.812 0 00-1.123 0s0 0 0 0L1.334 10.09v.001a.126.126 0 01-.043.027l.174.463-.174-.463a.124.124 0 01-.139-.035v-.001c-.034-.038-.044-.122.021-.185 0 0 0 0 0 0l8.308-7.949.005-.005.005-.005A.715.715 0 0110 1.75a.72.72 0 01.511.188h0l.01.01 2.263 2.163.845.809V2.5a.125.125 0 01.125-.125h1.875c.034 0 .065.013.089.037l.353-.354-.353.354a.125.125 0 01.036.088v4.455l.155.148 2.921 2.795h0a.15.15 0 01.045.103.114.114 0 01-.029.086h0a.124.124 0 01-.177.004h0l-8.106-7.746zm0 0l-.346.361m.346-.361h0l-.346.361m0 0l8.106 7.746L.774 10.41a.627.627 0 00.906.042l8.105-7.746a.313.313 0 01.432 0z" _fill="currentColor" _stroke="currentColor"/>'
  }
})
