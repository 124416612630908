/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'userIcon': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<circle pid="0" cx="20" cy="20" r="20" _fill="#CCC"/><path pid="1" d="M23.592 11.027C22.68 10.042 21.406 9.5 20 9.5c-1.414 0-2.692.54-3.6 1.518-.918.99-1.365 2.334-1.26 3.786C15.348 17.67 17.528 20 20 20c2.472 0 4.648-2.33 4.86-5.195.106-1.439-.344-2.78-1.268-3.778zM28.25 30.5h-16.5a1.454 1.454 0 01-.626-.133 1.455 1.455 0 01-.508-.389 1.655 1.655 0 01-.337-1.364c.396-2.195 1.63-4.038 3.571-5.333C15.574 22.132 17.758 21.5 20 21.5c2.242 0 4.426.633 6.15 1.781 1.94 1.294 3.175 3.138 3.571 5.332.09.503-.032 1-.337 1.365a1.453 1.453 0 01-1.134.522z" _fill="#000"/>'
  }
})
