/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'achievement': {
    width: 48,
    height: 49,
    viewBox: '0 0 48 49',
    data: '<rect pid="0" y=".5" width="48" height="48" rx="24" _fill="#F5EEFC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M34 12.5H14a2.675 2.675 0 00-2.667 2.667v4h25.334v-4c0-1.467-1.2-2.667-2.667-2.667zm0 24h-4V21.833h6.667v12c0 1.467-1.2 2.667-2.667 2.667zm-6.667-14.64h-6.666V36.5h6.666V21.86zM14 36.5a2.675 2.675 0 01-2.667-2.667v-12H18V36.5h-4z" _fill="#9B51E0"/>'
  }
})
