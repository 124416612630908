/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'response': {
    width: 48,
    height: 49,
    viewBox: '0 0 48 49',
    data: '<rect pid="0" y=".5" width="48" height="48" rx="24" _fill="#ECF4FD"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22.653 19.166c0 2.214-1.773 4-3.986 4-2.214 0-4-1.786-4-4 0-2.213 1.786-4 4-4a3.983 3.983 0 013.986 4zm10.667 0c0 2.214-1.773 4-3.987 4-2.213 0-4-1.786-4-4 0-2.213 1.787-4 4-4a3.983 3.983 0 013.987 4zm-14.653 6.667c-3.107 0-9.334 1.56-9.334 4.667v2c0 .733.6 1.333 1.334 1.333h16c.733 0 1.333-.6 1.333-1.333v-2c0-3.107-6.227-4.667-9.333-4.667zm9.373.067c.467-.04.907-.067 1.293-.067 3.107 0 9.334 1.56 9.334 4.667v2c0 .733-.6 1.333-1.334 1.333h-6.906c.146-.413.24-.867.24-1.333v-2c0-1.96-1.054-3.44-2.574-4.547l-.012-.015a.122.122 0 00-.041-.038z" _fill="#2F80ED"/>'
  }
})
