const layout = () =>
    import ('@/layouts/default.vue')
const authlayout = () =>
    import ('@/layouts/auth.vue')
const dashboard = () =>
    import ('@/pages/dashboard/dashboard.vue')
const dashboardView = () =>
    import ('@/pages/dashboard/dashboardView.vue')
const ParticularDetails = () =>
    import ('@/pages/components/ParticularDetails.vue')
const EnterpriseDetails = () =>
    import ('@/pages/components/EnterpriseDetails.vue')
const admin = () =>
    import ('@/pages/admin/admin.vue')
const Profil = () =>
    import ('@/pages/dashboard/Profil')
const activity = () =>
    import ('@/pages/activity/activity.vue')


const routes = [{
        path: '/',
        component: layout,
        children: [{
                path: "/",
                icon: "Home",
                title: "Tableau de bord",
                name: "dashboard",
                component: dashboard,
                children: [{
                        path: "",
                        name: "dashboard",
                        component: dashboardView,
                    },
                    {
                        path: "particular/:id",
                        name: "dashboard",
                        component: ParticularDetails,
                        props: true
                    },
                    {
                        path: "enterprise/:id",
                        name: "dashboard",
                        component: EnterpriseDetails,
                        props: true
                    }
                ],
                meta: { requiresAuth: true }
            },
            {
                path: "/activity",
                icon: "Copy",
                title: "Journal",
                name: "activity",
                component: activity,
                meta: { requiresAuth: true }
            },
            {
                path: "/admins",
                icon: "User",
                title: "Utilisateurs",
                name: "utilisateurs",
                component: admin,
                meta: { requiresAuth: true }
            },
        ]

    }, {
        path: '/profil',
        component: layout,
        children: [{
            path: '',
            component: Profil,
            meta: { requiresAuth: true }
        }]
    },
    {
        path: "/",
        component: authlayout,
        children: [
            {
                path: "login",
                icon: "",
                title: "Connexion",
                name: "Connexion",
                component: () =>
                    import ("@/pages/login/login.vue")
            },
            {
                path: "reset-password",
                icon: "",
                title: "Reset Password",
                name: "reset-password",
                component: () =>
                    import ("@/pages/reset-password/reset-password.vue")
            },
            {
                path: "invitations/confirmed/:reference",
                icon: "",
                title: "Register",
                name: "register",
                component: () =>
                    import ("@/pages/register/register.vue")
            },
        ]
    },
]
export default routes;