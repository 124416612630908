import { Model } from '@vuex-orm/core'

export default class User extends Model {

    static entity = 'user'
    static primaryKey = '_id'

    static fields() {
        return {
            _id: this.attr(null),
            status: this.attr(''),
            activitySector: this.attr(''),
            employeesNumber: this.attr(''),
            firstName: this.attr(''),
            lastName: this.attr(''),
            email: this.attr(''),
            telephone: this.attr(''),
            idCard: this.attr(''),
            office: this.attr(''),
            focalPoint: this.attr(''),
            accountStatus: this.attr(''),
            accountEmail: this.attr('')
        }
    }
}