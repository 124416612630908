import Vue from 'vue'
import VueRouter from 'vue-router'
import Routes from '@/routes'
import store from '@/store'
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [...Routes]
})

router.beforeEach(async (to, from, next) => {
  let authenticated = store.getters['auth/authenticated'];
  // console.log('authenticated on guard ', authenticated);
  
  if (!authenticated) {
    const token = localStorage.getItem('bni_admin_token');
    if (token) {
      try {
        authenticated =  await store.dispatch('auth/checkToken', token)
        if (!authenticated) {
          localStorage.removeItem('bni_admin_token')
        }
      } catch (error) {
        // 
      }
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authenticated) {
      next({path: '/login', query: {redirect: to.fullPath}})
    }else{
      next()
    }
  }else {
    next()
  }
})

export default router