/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrowNext': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.94 9L5.47 3.53l1.06-1.06L13.06 9l-6.53 6.53-1.06-1.06L10.94 9z" _fill="#A6B6C8"/>'
  }
})
