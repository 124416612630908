import instanceApi from '@/api'
import Particular from '../models/particular';
const state = {};

const getters = {}

const mutations = {}

const actions = {
    getAllParticularUsers() {
        instanceApi.get('/person')
            .then((response) => {
                const persons = response.data.data
                persons.forEach(user => {
                    Particular.insert({ data: user })
                });
            })
            .catch(error => {
                console.log(error);
            })
    },

    getPaginateParticular (_, params) {
        return new Promise((resolve, reject) => {
          instanceApi.get('/person/paginate', {params})
          .then((response) => {
              console.log('partUsers ', response);
              const particulars = response.data.data
              particulars.forEach(user => {
                  Particular.insert({data: user})
              });
              resolve(response.data)
          })
          .catch(error => {
              console.log(error);
              reject(error.statusText)
          })
      })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true

}