<template>
  <div class="v-table" v-if="columns.length > 0">
    <table :class="['table table-fixed w-full p-4', vTableClass.tableClass]">
      <thead>
        <tr>
          <template v-for="(column, index) of tableHeaderColumns">
            <template v-if="column.visible">
              <template v-if="isSpecialColumn(column.name)">
                <th
                  v-if="specialColumnIs(column.name, '__slot')"
                  :key="index"
                  :class="[
                    'v-table-th-' + extractName(column.name),
                    columnALignement(column),
                    column.titleClass
                  ]"
                  :style="{ width: column.width }"
                  v-html="titleRender(column)"
                ></th>
              </template>
              <th
                v-else
                :key="index"
                :class="[column.titleClass, columnALignement(column)]"
                :style="{ width: column.width }"
                v-html="titleRender(column)"
              ></th>
            </template>
          </template>
        </tr>
      </thead>

      <tbody>
        <template v-if="!loading">
          <template v-for="(rowData, l) of tableBodyRowsData">
            <tr :class="setRowClass(rowData, l)" :key="l">
              <template v-for="(column, c) of tableHeaderColumns">
                <template v-if="column.visible">
                  <template v-if="isSpecialColumn(column.name)">
                    <td
                      v-if="specialColumnIs(column.name, '__slot')"
                      :key="c"
                      :class="[columnALignement(column), column.dataClass]"
                    >
                      <slot
                        :name="extractName(column.name)"
                        :data="rowData"
                        :rowData="rowData"
                        :rowIndex="l"
                      >
                        <div
                          v-html="
                            column.transform
                              ? column.transform(rowData, column)
                              : getObjectValue(rowData, extractName(column.name))
                          "
                        ></div>
                      </slot>
                    </td>
                  </template>
                  <td
                    v-else
                    :key="c"
                    :class="[columnALignement(column), column.dataClass]"
                    v-html="
                      column.transform
                        ? column.transform(rowData, column)
                        : getObjectValue(rowData, column.name)
                    "
                  ></td>
                </template>
              </template>
            </tr>
            <template v-if="useRowDetail">
              <transition name="rowDetailTransition" :key="l">
                <tr
                  v-if="isVisibleRowDetail(l)"
                  :class="vTableClass.rowDetailClass"
                  @click="onRowDetailClick(rowData, $event)"
                >
                  <td :colspan="visibleColumnLenght">
                    <component
                      :is="detailRowComponent"
                      :fields="tableHeaderColumns"
                      :rowData="rowData"
                      :rowIndex="l"
                    ></component>
                  </td>
                </tr>
              </transition>
            </template>
          </template>
        </template>
        <transition name="fade" mode="out-in">
          <!-- Loading area -->
          <template v-if="displayLoading">
            <tr>
              <td :colspan="visibleColumnLenght">
                <slot name="loading">
                  <div class="flex justify-center py-6 text-lg">
                    <div class="dots">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </slot>
              </td>
            </tr>
          </template>

           <!-- No data Available -->
          <template v-else-if="!displayLoading && tableData.length === 0">
            <tr>
              <td :colspan="visibleColumnLenght">
                <slot name="no-data">
                  <div class="flex flex-col justify-center items-center py-4 text-lg">
                    <!-- <img style="height: 300px; width:300px" src="@/assets/svg/norecentactivity.svg" /> -->
                    <div>
                      <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M116 208C163.496 208 202 169.496 202 122C202 74.5035 163.496 36 116 36C68.5035 36 30 74.5035 30 122C30 169.496 68.5035 208 116 208Z" fill="#EEF8FE"/>
                        <path d="M116 208C163.496 208 202 169.496 202 122C202 74.5035 163.496 36 116 36C68.5035 36 30 74.5035 30 122C30 169.496 68.5035 208 116 208Z" fill="#EEF8FE"/>
                        <path d="M155.655 81.6552C154.632 81.6552 153.608 81.2643 152.827 80.4833L141.515 69.1712C139.953 67.6092 139.953 65.0773 141.515 63.5152C143.077 61.9532 145.609 61.9532 147.171 63.5152L158.483 74.8273C160.045 76.3893 160.045 78.9213 158.483 80.4833C157.702 81.2653 156.679 81.6552 155.655 81.6552V81.6552Z" fill="#BAC7D5"/>
                        <path d="M144.343 81.6552C143.32 81.6552 142.296 81.2643 141.515 80.4833C139.953 78.9213 139.953 76.3893 141.515 74.8273L152.827 63.5152C154.389 61.9532 156.921 61.9532 158.483 63.5152C160.045 65.0773 160.045 67.6092 158.483 69.1712L147.171 80.4833C146.39 81.2653 145.366 81.6552 144.343 81.6552Z" fill="#BAC7D5"/>
                        <path d="M194 92C196.209 92 198 90.2091 198 88C198 85.7909 196.209 84 194 84C191.791 84 190 85.7909 190 88C190 90.2091 191.791 92 194 92Z" fill="#BAC7D5"/>
                        <path d="M182 48C184.209 48 186 46.2091 186 44C186 41.7909 184.209 40 182 40C179.791 40 178 41.7909 178 44C178 46.2091 179.791 48 182 48Z" fill="#BAC7D5"/>
                        <path d="M242 260H82C75.373 260 70 254.627 70 248V240H254V248C254 254.627 248.627 260 242 260Z" fill="#EEF8FE"/>
                        <path d="M242 264H82C73.178 264 66 256.822 66 248V240C66 237.791 67.791 236 70 236H254C256.209 236 258 237.791 258 240V248C258 256.822 250.822 264 242 264ZM74 244V248C74 252.412 77.588 256 82 256H242C246.412 256 250 252.412 250 248V244H74Z" fill="#BAC7D5"/>
                        <path d="M110.76 248H251.046C259.305 248 266.001 241.454 266.001 233.379V124C266.001 115.163 258.838 108 250.001 108H133.143L124.268 89.592C121.59 85.926 117.265 83.75 112.657 83.75H68.089C60.308 83.75 54 89.917 54 97.524V107.214V124.812V233.379C54 241.454 60.695 248 68.955 248H90.102" fill="#EEF8FE"/>
                        <path d="M251.045 252H110.76C108.551 252 106.76 250.209 106.76 248C106.76 245.791 108.551 244 110.76 244H251.045C257.086 244 262 239.236 262 233.379V124C262 117.383 256.617 112 250 112H133.143C131.608 112 130.207 111.121 129.539 109.736L120.836 91.685C118.902 89.214 115.869 87.749 112.656 87.749H68.088C62.525 87.75 58 92.135 58 97.523V233.378C58 239.236 62.914 244 68.955 244H90.101C92.31 244 94.101 245.791 94.101 248C94.101 250.209 92.31 252 90.101 252H68.955C58.504 252 50 243.646 50 233.379V97.523C50 87.722 58.113 79.75 68.088 79.75H112.656C118.527 79.75 124.076 82.547 127.498 87.232C127.641 87.427 127.766 87.636 127.871 87.855L135.654 104H250C261.027 104 270 112.973 270 124V233.379C270 243.646 261.496 252 251.045 252V252Z" fill="#BAC7D5"/>
                        <path d="M134 112H106C103.791 112 102 110.209 102 108C102 105.791 103.791 104 106 104H134C136.209 104 138 105.791 138 108C138 110.209 136.209 112 134 112Z" fill="#BAC7D5"/>
                      </svg>
                    </div>
                    <div class="my-6" v-html="noData"></div>
                  </div>
                </slot>
              </td>
            </tr>
          </template>
        </transition>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import Axios from "axios";
// import VTableLine from "./VTableLine.vue";
export default {
  // components: { VTableLine },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },

    data: {
      type: [Array, Object],
      default() {
        return [];
      }
    },

    transform: {
      type: Function,
      default(data) {
        return data;
      }
    },

    transformResponseData: {
      type: Function,
      default(data, response) {
        return data;
      }
    },

    loadOnApiMode: {
      type: Function,
      default(parameters) {
        const { url, method, options, request, data } = parameters;
        let makeRequest;
        if (method.toLowerCase() === "post") {
          makeRequest = request[method](url, data, options);
        } else if (method.toLowerCase() === "get") {
          makeRequest = request[method](url, options);
        }
        return makeRequest;
      }
    },

    apiMode: {
      type: Boolean,
      default: false
    },

    showLoader: {
      type: Boolean,
      default: true
    },

    dataPath: {
      type: String,
      default: "data"
    },

    paginationPath: {
      type: String,
      default: ""
    },

    perPage: {
        type: Number,
        default: 10
    },
    initialPage: {
      type: Number,
      default: 1
    },

    apiUrl: {
      type: String,
      default: ""
    },

    httpMethod: {
      type: String,
      default: "get"
    },

    httpRequest: {
      type: [Object, Function],
      default() {
        return Axios;
      }
    },

    httpOptions: {
      type: Object,
      default(){
        return {}
      }
    },

    httpData: {},

    queryParams:{
      type: [Object, Function],
      default(){
        return {
          // sort: 'sort',
          page: 'page',
          perPage: 'perPage'
        }
      }
    },

    appendParams:{
      type: Object,
      default(){
        return {}
      }
    },


    rowKey: {
      type: String,
      default: "id"
    },

    rowClass: {
      type: [String, Function],
      default() {
        return "hover:bg-blueLighter-simple";
      }
    },

    detailRowComponent: {
      type: [String, Object, Function],
      default: undefined
    },

    css: {
      type: Object,
      default() {
        return {
          tableClass: 'bg-white'
        };
      }
    },

    emptyDataMessage: {
      type: String,
      default: "Aucune donnée actuellement disponible"
    }
  },

  data() {
    return {
      tableData: [],
      tablePaginationData: null,
      visibleRowDetails:[],
      loading: false,
      noData: this.emptyDataMessage,
      currentPage: this.initialPage,
    };
  },

  methods: {
    parseColumnFromString(column) {
      const col = {
        ...this.defaultHeaderOptions,
        name: column,
        title: this.titleRender({ name: column })
      };

      return col;
    },

    parseColumnFromObject(column) {
      const col = {
        ...this.defaultHeaderOptions,
        ...column
      };

      return col;
    },

    titleRender(column) {
      return this.getTitle(column);
    },

    getTitle(column) {
      if (typeof column.title === "function") {
        return column.title();
      }

      if (typeof column.title === "undefined") {
        const name = this.isSpecialColumn(column.name)
          ? this.extractName(column.name)
          : column.name;
        return name.indexOf(".") === -1
          ? name.charAt(0).toUpperCase() + name.substr(1)
          : name.replace(".", " ");
      }
      // console.log("column ", column);

      return column.title;
    },

    isSpecialColumn(name) {
      const isSpecialColumn = name.substr(0, 2) === "__";
      // console.log("isSpecialColumn " + name, isSpecialColumn);

      return isSpecialColumn;
    },

    specialColumnIs(specialColumn, field) {
      return this.extractArg(specialColumn) === field;
    },

    extractArg(specialColumn) {
      if (this.isSpecialColumn(specialColumn)) {
        return specialColumn.split(":")[0];
      }
      return specialColumn;
    },

    extractName(specialColumn) {
      if (this.isSpecialColumn(specialColumn)) {
        return specialColumn.split(":")[1];
      }
      return specialColumn;
    },

    isVisibleRowDetail(rowKey) {
      return this.visibleRowDetails.indexOf(rowKey) >= 0;
    },

    toggleRowDetail(rowKey) {
      if (this.isVisibleRowDetail(rowKey)) {
        this.hideRowDetail(rowKey);
      } else {
        this.showRowDetail(rowKey);
      }
    },

    showRowDetail(rowKey) {
      if (!this.isVisibleRowDetail(rowKey)) {
        this.visibleRowDetails.push(rowKey);
      }
    },

    hideRowDetail(rowKey) {
      if (this.isVisibleRowDetail(rowKey)) {
        const index = this.visibleRowDetails.indexOf(rowKey);
        this.visibleRowDetails.splice(index, 1);
      }
    },

    onRowDetailClick(row, event) {
      this.fireEvent("row-detail-click", row, event);
    },

    fireEvent(name, ...args) {
      console.log("name ", name);
      console.log("args ", args);

      this.$emit(name, args);
    },

    setRowClass(row, index) {
      return typeof this.rowClass === "function"
        ? this.rowClass(row, index)
        : this.rowClass;
    },

    getObjectValue(object, path, throwError= false) {
      path = path.trim();
      let value;
      if (!path) {
        return;
      }

      if (path.indexOf(".") === -1) {
        if (
          typeof object === "object" &&
          object[path] !== undefined &&
          object[path] !== null
        ) {
          value = object[path];
        } else {
          // console.log(` typeof object !== "object" ${typeof object !==
          //   "object"} \n
          // object[path] !== undefined ${object[path] !== undefined} \n
          // object[path] !== null ${object[path] !== null} \n
          // `);

          // console.warn(
          //   `VTable: object is not object or has no property ${path} for object \n`,
          //   object
          // );
          if (throwError) {
           console.error(`VTable: object is not object or has no property ${path} for object \n`,object)
          }
          return;
        }
      } else {
        const keys = path.split(".");
        value = object;
        keys.forEach((key, i) => {
          if (
            value !== null &&
            value !== undefined &&
            typeof value[key] !== undefined &&
            value[key] !== null
          ) {
            value = value[key];
          } else {
            const currentPath = keys.slice(0, i + 1).toString();
            if (throwError) {
              console.warn(
                `VTable: object is not defiened or has no property ${currentPath} for object \n`,
                object
              );
            }
            return;
          }
        });
      }
      // console.log("VTable column value____ ", value);

      return value;
    },

    columnALignement(column) {
      let align = "";
      switch (column.align) {
        case "center":
          align = "text-center";
          break;
        case "left":
          align = "text-left";
          break;
        case "right":
          align = "text-right";
          break;
      }
      return align;
    },

    refresh() {
      console.log("refresh table ");

      this.visibleRowDetails = [];
      this.tableData = [];
      this.loadData();
    },

    loadData () {
      //
      if (!this.isApiMode) {
        this.loadOnDataMode(this.data);
      } else {
        if (!this.apiUrl) {
          console.warn("VTable: apiUrl not defined on apiMode ");
        }
        this.httpOptions['params'] = this.getParams(this.getDefaultQueryParams())
        const options = {
          url: this.apiUrl,
          method: this.httpMethod,
          data: this.httpData,
          options: this.httpOptions,
          request: this.httpRequest
        };

        this.loading = true;
        this.$emit("loading");
        this.loadOnApiMode(options)
          .then((response) => {
            this.$emit("load-success", response.data);
            // console.log("VTable: response : ", response);

              const responseData = this.transformResponseData(response.data, response)
              if (Array.isArray(responseData)) {
                this.tableData = this.transform(responseData);
                this.$emit('loaded')
              } else {
                const data = this.transform(responseData);
                this.tableData = this.getObjectValue(data, this.dataPath, true); //Throw warning in this cas
                if (this.paginationPath) {
                  this.tablePaginationData = this.makePagination(this.getObjectValue(data, this.paginationPath)); //Throw warning in this cas
                  console.log('loadOnApiMode --> tablePaginationData: ', this.tablePaginationData);

                }
                this.$nextTick(() => {
                  if (this.paginationPath) {
                    this.$emit('pagination-data', this.tablePaginationData)
                  }
                  this.$emit('loaded')
                })
              }
          })
          .catch((error) => {
            //
            console.log('VTable: error : ',error);
            this.loadError(error);
          })
          .finally(() => {
            this.loading = false;
            // console.log('VTable: finally loading : ',this.loading);
          });
      }
    },

    getParams(params){
      for (const appendParam in this.appendParams) {
        params[appendParam] = this.appendParams[appendParam]
      }
      return params
    },

    getDefaultQueryParams(){
      let params= {}

      if (typeof this.queryParams === 'function') {
        params = this.queryParams(this.currentPage, this.perPage)
        return params && typeof params === 'object' ? params : {}
      }
      params[this.queryParams.page] = this.currentPage;
      params[this.queryParams.perPage] = this.perPage;

      return params
    },

    loadOnDataMode(responseData) {
      this.$emit('loading');

      if (Array.isArray(responseData)) {
        this.tableData = this.transform(responseData);
        this.$emit('loaded')
      } else {
        const data = this.transform(responseData);
        this.tableData = this.getObjectValue(data, this.dataPath, true); //Throw warning in this cas
        if (this.paginationPath) {
          this.tablePaginationData = this.makePagination(this.getObjectValue(data, this.paginationPath))
        }
      }
      this.$nextTick(() => {
        if (this.paginationPath) {
          this.$emit('pagination-data', this.tablePaginationData)
        }
        this.$emit('loaded')
      })
    },

    parseTableHeaderColumns(columnData){
      //
      const columns = [];
      if (columnData.length > 0) {
        columnData.forEach(column => {
          if (typeof column === "string") {
            columns.push(this.parseColumnFromString(column));
          }
          if (typeof column === "object") {
            columns.push(this.parseColumnFromObject(column));
          }
        });
      }
      // console.log("Table columns ", columns);

      return columns;
    },

    loadError(response) {
      this.noData = `<div class="text-error text-lg font-bold" >${response}</div>`;
      this.$emit("load-error", response);
      this.$emit("loaded");
    },

    setEmptyDataMessage(message){
      this.noData = message
    },

    changePage (page) {
      console.log('VTable: change page ', page);

      if (page === 'prev') {
        this.gotoPreviousPage()
      } else if (page === 'next') {
        this.gotoNextPage()
      } else {
        this.gotoPage(page)
      }
    },

    gotoPreviousPage(){
      if (this.currentPage > 1) {
        this.currentPage--
        this.loadData()
      }
    },

    gotoNextPage(){

      if (this.tablePaginationData !== null && this.tablePaginationData.lastPage) {
        if (this.currentPage < this.tablePaginationData.lastPage) {
          this.currentPage++
          this.loadData()
        }
      }else {
        console.warn('VTable: gotoNextPage tablePaginationData is possibly undefined');
      }
    },

    gotoPage(page){
      // console.log('tablePaginationData: ', this.tablePaginationData);

      if (this.tablePaginationData !== null && this.tablePaginationData.lastPage) {
        if (page != this.currentPage && (page > 0 && page <= this.tablePaginationData.lastPage)) {
          this.currentPage = page
          this.loadData()
        }
      }else {
        console.warn('VTable: gotoPage tablePaginationData is possibly undefined');
      }
    },

    makePagination(paginationData){
      if (paginationData === null || paginationData === undefined) {
        console.warn('VTable: pagination data is null');
        return null;
      }
      const pagination = {
        total: paginationData.total || 0,
        totalPage: paginationData.totalPage || Math.ceil((paginationData.totalPage || 1) / (paginationData.perPage || 1)),
        lastPage: paginationData.totalPage || Math.ceil((paginationData.totalPage || 1) / (paginationData.perPage || 1)),
        perPage: paginationData.perPage || this.perPage,
        currentPage: paginationData.currentPage || this.currentPage
      }
      return pagination
    }
  },

  watch: {
    apiUrl(val) {
      if (this.apiMode) {
        this.loadData();
      }
    },

    apiMode(val, old) {
      if (val !== old) {
        this.loadData();
      }
    },

    data(val,old){
      console.log('VTable: data change');
      this.loadData()
    },
    emptyDataMessage(val){
      this.setEmptyDataMessage(val)
    }
  },

  computed: {
    tableHeaderColumns(){
      return this.parseTableHeaderColumns(this.columns);
    },

    useRowDetail() {
      // console.log("$parent ", this.$parent);
      // console.log("typeof detailRowComponent", typeof this.detailRowComponent);
      // console.log("useRowDetail", !!this.detailRowComponent);

      return !!this.detailRowComponent;
    },

    visibleColumnLenght() {
      const columns = this.tableHeaderColumns;
      return columns.reduce((acc, column) => (column.visible ? ++acc : acc), 0);
    },

    tableBodyRowsData() {
      // console.log("Table data ", this.tableData);

      return this.$data.tableData;
    },

    vTableClass(){
      return {
        ...this.css
      };
    },

    displayLoading() {
      return this.showLoader && this.$data.loading;
    },

    isApiMode() {
      return this.apiMode;
    },

    defaultHeaderOptions() {
      const THeaderOptions= {
        name: "",
        width: "auto",
        center: false,
        align: "left",
        visible: true,
        titleClass: "p-3 font-bold text-sm text-inkDark border-b border-borderColor",
        dataClass: "pl-4"
      };

      return THeaderOptions;
    }
  },
  mounted() {
    this.loadData();
    // console.log("VTable: mounted___");
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active{
  transition: all .4s ease;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #4661B9;;
  animation: fade 1.5s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


</style>