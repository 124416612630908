/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrowPrev': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M13 6.902l-6.07 6.07L13 19.044l1.5-1.5-4.57-4.57 4.57-4.57-1.5-1.5z" _fill="#000"/>'
  }
})
