import instanceApi from '@/api'
const state = {};

const getters = {}

const mutations = {}

const actions = {
    getPaginateActivity(_, { params }) {
        return new Promise((resolve, reject) => {
            instanceApi.get('activity/paginate', {
                    params
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}