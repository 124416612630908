/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.158 2.667a2.497 2.497 0 11-4.992 0 2.497 2.497 0 114.992 0zm6.667 0a2.497 2.497 0 11-4.992 0 2.497 2.497 0 114.992 0zM6.667 6.833c-1.942 0-5.834.975-5.834 2.917V11c0 .458.375.833.834.833h10A.836.836 0 0012.5 11V9.75c0-1.942-3.892-2.917-5.833-2.917zm5.858.042c.292-.025.567-.042.808-.042 1.942 0 5.834.975 5.834 2.917V11a.836.836 0 01-.834.833h-4.316c.091-.258.15-.542.15-.833V9.75c0-1.225-.659-2.15-1.609-2.842a.094.094 0 01-.007-.01c-.007-.007-.014-.017-.026-.023z" _fill="currentColor"/>'
  }
})
