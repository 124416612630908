<template>
  <button
    :class="['appearance-none', computedClasses, btnClass]"
    v-on="$listeners"
    :type="type"
    :disabled="disabled"
  >
    <template v-if="icon">
      <slot name="icon">
        <component :is="icon" v-bind="iconOption" />
      </slot>
    </template>
    <template v-if="loading">
      <slot name="loader">
        <div class="flex justify-center">
          <loader />
        </div>
      </slot>
    </template>
    <template v-else>
      <span v-if="text" v-html="text"></span>
    </template>
  </button>
</template>


<script>
import Loader from './Loader.vue';
export default {
  components: {
    Loader
  },
  props: {
    type: {
      type: String,
      default: "button"
    },
    color: {
      type: String,
      default: ""
    },

    outline: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: true
    },

    full: {
      type: Boolean,
      default: false
    },

    btnClass: {
      type: String,
      default: ""
    },

    btnHeight: {
      type: String,
      default: "h-10"
    },

    text: {
      type: String,
      default: ""
    },

    icon: {
      type: String,
      default: ""
    },

    disabled: {
      type: Boolean,
      default: false
    },

    applyDisabledColor: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedClasses(){
      return [
        "outline-none focus:outline-none",
        this.btnColors,
        this.rounded && "rounded-lg",
        this.full && "w-full",
        this.btnHeight,
        this.loading && "loading",
        this.disabled && this.applyDisabledColor && "disabled"
      ];
    },

    btnColors() {
      let color = "";
      switch (this.color) {
        case "primary":
          color = this.outline
            ? "bg-transparent border-2 border-primary-100 text-primary-100 hover:border-primary-200 hover:text-primary-100"
            : "text-white px-4 bg-primary-100 font-bold hover:bg-primary-100";
          break;
        
        case "blue":
          color = this.outline
            ? "bg-transparent border-2 border-blueLink text-blueLink hover:border-blueLink hover:text-blueLink"
            : "text-white px-4 bg-blueLink font-bold hover:bg-blueLink";
          break;
        case "gray":
          color = this.outline
            ? "bg-transparent border-2 border-gray-150 text-gray-150 hover:border-gray-150 hover:text-gray-150"
            : "text-black px-4 bg-gray-150 font-bold hover:bg-gray-150";
          break;

        // case "secondary":
        //   color = this.outline
        //     ? "bg-transparent border text-kbutton-1 border-kbutton-1 h-search sm:h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base focus:outline-none"
        //     : "text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 px-4 sm:px-6 text-sm sm:text-base focus:outline-none";
        //   break;
        default:
          color = this.color
      }
      return color;
    },
  }
}
</script>

<style lang="postcss" scoped>
.loading {
  @apply pointer-events-none text-transparent;
}
.disabled {
  @apply pointer-events-none bg-gray-300 text-gray-500 border-gray-400 cursor-not-allowed;
}
</style>