/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.667.667h-10C4.75.667 4 1.417 4 2.333v10C4 13.25 4.75 14 5.667 14h10c.916 0 1.666-.75 1.666-1.667v-10c0-.917-.75-1.666-1.666-1.666zM1.5 4a.836.836 0 00-.833.833v10.834c0 .916.75 1.666 1.666 1.666h10.834A.836.836 0 0014 16.5a.836.836 0 00-.833-.834h-10a.836.836 0 01-.834-.833v-10A.836.836 0 001.5 4zm5.833 4.166H14a.836.836 0 00.833-.833A.836.836 0 0014 6.5H7.333a.836.836 0 00-.833.833c0 .458.375.833.833.833zm3.334 3.334H7.333a.836.836 0 01-.833-.834c0-.458.375-.833.833-.833h3.334c.458 0 .833.375.833.833a.836.836 0 01-.833.834zM7.333 4.833H14A.836.836 0 0014.833 4 .836.836 0 0014 3.167H7.333A.836.836 0 006.5 4c0 .458.375.833.833.833z" _fill="currentColor"/>'
  }
})
