/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'allEnterprise': {
    width: 48,
    height: 49,
    viewBox: '0 0 48 49',
    data: '<rect pid="0" y=".5" width="48" height="48" rx="24" _fill="#FEF4EC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24 15.167v2.666h10.667c1.466 0 2.666 1.2 2.666 2.667v13.333c0 1.467-1.2 2.667-2.666 2.667H13.333a2.675 2.675 0 01-2.666-2.667V15.167c0-1.467 1.2-2.667 2.666-2.667h8C22.8 12.5 24 13.7 24 15.167zM13.333 33.833H16v-2.666h-2.667v2.666zM16 28.5h-2.667v-2.667H16V28.5zm-2.667-5.333H16V20.5h-2.667v2.667zM16 17.833h-2.667v-2.666H16v2.666zm2.667 16h2.666v-2.666h-2.666v2.666zm2.666-5.333h-2.666v-2.667h2.666V28.5zm-2.666-5.333h2.666V20.5h-2.666v2.667zm2.666-5.334h-2.666v-2.666h2.666v2.666zm2.667 16h9.333c.734 0 1.334-.6 1.334-1.333V21.833c0-.733-.6-1.333-1.334-1.333H24v2.667h2.667v2.666H24V28.5h2.667v2.667H24v2.666zm8-10.666h-2.667v2.666H32v-2.666zM29.333 28.5H32v2.667h-2.667V28.5z" _fill="#F2994A"/>'
  }
})
