/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backArrow': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.414 13.758l6.293 6.293-1.414 1.414-8.707-8.707 8.707-8.707 1.414 1.414-6.293 6.293H21v2H6.414z" _fill="#000"/>'
  }
})
