import instanceApi from '@/api'
const state = {};

const getters = {}

const mutations = {}

const actions = {
    getPaginateAdmin(_, { params }) {
        return new Promise((resolve, reject) => {
            instanceApi.get('admin/paginate', {
                    params
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    },

    sendInvitation(_, { invitation }) {
        return new Promise((resolve, reject) => {
            instanceApi.post('invitation', invitation).then(response => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        })
    },

    createAdminByInvitation({ commit }, { adminData, reference }) {
        return new Promise((resolve, reject) => {
            instanceApi.post(`invitation/confirmed/${reference}`, adminData).then(response => {
                    console.log('response ', response);

                    const auth = response.data.user;
                    auth.token = response.data.token
                    commit('auth/setAuth', auth, { root: true })
                    resolve(response.data)
                })
                .catch(error => reject(error))
        });
    },

    updateAdmin(_, { id, adminData }) {
        return new Promise((resolve, reject) => {
            instanceApi.put(`admin/${id}`, adminData).then(response => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        });
    },

    deleteAdmin(_, { id }) {
        return new Promise((resolve, reject) => {
            instanceApi.delete(`admin/${id}`).then(response => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        });
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}