import { Model } from '@vuex-orm/core'

export default class Particular extends Model {

    static entity = 'particulars'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(null),
            accountNumber: this.attr(''),
            firstName: this.attr(''),
            lastName: this.attr(''),
            email: this.attr(''),
            parentName: this.attr(''),
            civility: this.attr(''),
            birthdate: this.attr(''),
            birthplace: this.attr(''),
            nationality: this.attr(''),
            maritalStatus: this.attr(''),
            partnerName: this.attr(''),
            numberChildren: this.attr(''),
            motherFullname: this.attr(''),
            fatherFullname: this.attr(''),
            phoneNumber: this.attr(''),
            otherNumber: this.attr(''),
            adresse: this.attr(''),
            isResident: this.attr(''),
            postalAdresse: this.attr(''),
            pi: this.attr(''),
            piUrl: this.attr(''),
            piUrlVerso: this.attr(''),
            piNumber: this.attr(''),
            piValidyDate: this.attr(''),
            piEtablishmentDate: this.attr(''),
            profession: this.attr(''),
            contract: this.attr(''),
            salaria: this.attr(''),
            earningSource: this.attr(''),
            otherEarning: this.attr(''),
            hasOtherActivies: this.attr(''),
            otherActivities: this.attr(''),
            employerSigle: this.attr(''),
            employerLocalisation: this.attr(''),
            employerCompanyName: this.attr(''),
            employerAdresse: this.attr(''),
            employerActivitySector: this.attr(''),
            employerPhone: this.attr(''),
            hasAnotherAccount: this.attr(''),
            anotherAccountNumber: this.attr(''),
            userId: this.attr(''),
            banques: this.attr(''),
        }
    }
}