import { Model } from '@vuex-orm/core'

export default class Enterprise extends Model {

    static entity = 'enterprises'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(null),
            accountNumber: this.attr(''),
            legalStatus: this.attr(''),
            companyName: this.attr(''),
            companyType: this.attr(''),
            createdDate: this.attr(''),
            activityType: this.attr(''),
            immatriculationDate: this.attr(''),
            rccm: this.attr(''),
            capital: this.attr(''),
            taxpayAccountNumber: this.attr(''),
            nationality: this.attr(''),
            postalAdresse: this.attr(''),
            adresse: this.attr(''),
            officePlace: this.attr(''),
            country: this.attr(''),
            fax: this.attr(''),
            email: this.attr(''),
            phoneNumber: this.attr(''),
            webSite: this.attr(''),
            ca: this.attr(''),
            agreementNumber: this.attr(''),
            degreeNumber: this.attr(''),
            hasAnotherAccount: this.attr(''),
            userId: this.attr(''),
            dirigeants: this.attr(''),
            banques: this.attr(''),
        }
    }
}
/* {
  "ca": 0,
  "agreementNumber": "string",
  "degreeNumber": "string",
  "hasAnotherAccount": true,
  "userId": "string"
} */