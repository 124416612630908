import axios from 'axios'
import instanceApi from '@/api'
const state = {
    auth: null,
    token: '',
    authenticated: false,
    username: ''
};

const getters = {
    authenticated(state) {
        return state.authenticated
    },

    authUser(state) {
        return state.auth
    },
    getUsername (state) {
        return state.username
    }
}

const mutations = {
    setAuthInfo(state, authInfos) {
        state.auth = authInfos
    },
    setAuth(state, auth) {
        console.log('auth --->', auth);

        state.auth = auth
        state.token = auth.token

        // console.log('token ', auth.token);

        instanceApi.defaults.headers.common['Authorization'] = `Bearer ${state.token}`
        state.authenticated = true;
        console.log('authenticated state ', state.authenticated);

        localStorage.setItem('bni_admin_token', auth.token)
    },

    setUsername (state, username) {
        state.username = username
    }
}

const actions = {
    login({ commit }, credential) {
        return new Promise((resolve, reject) => {
            instanceApi.post('admin/login', credential)
                .then(response => {
                    const auth = response.data.user;
                    auth.token = response.data.token;
                    commit('setAuth', auth)
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    },

    checkToken({ commit }, token) {
        return new Promise((resolve) => {
            axios.get('admin/me', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    //  console.log('check auth -->', response.data);
                    const auth = response.data.user;
                    auth.token = response.data.token;
                    commit('setAuth', auth)
                    resolve(true)
                })
                .catch(() => {
                    resolve(false)
                })
        })
    },

    requestPasswordToken (_, data) {
        return new Promise((resolve, reject) => {
            instanceApi.post('admin/password/token', data)
                .then(response => {
                    
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    },

    
    checkPasswordToken (_, data) {
        return new Promise((resolve, reject) => {
            instanceApi.post('admin/password/check-token', data)
                .then(response => {
                    
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    },

    changePassword (_, {token, data}) {
        return new Promise((resolve, reject) => {
            instanceApi.post(`admin/password/reset/${token}`, data)
                .then(response => {
                    
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err.response)
                })
        })
    },



    logout() {
        localStorage.removeItem('bni_admin_token')
        window.location.reload()
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}