<template>
    <div class="">
        <label
        :class="[
            ' text-sm font-bold flex items-center space-x-1',
            checkable ? 'flex items-center ml-2' : 'mb-2'
        ]"
        v-if="label"
        :for="name"
        >
        <div v-html="label"></div>
        <slot name="pre-label"></slot>
        </label>
        
      <div :class="($slots.prepend || $slots.append) ? (errors ? `${wrapperErrors}` : `${[wapperClass]}`) : ''">
        <div class="input-group-prepend" v-if="$slots.prepend">
          <slot name="prepend"></slot>
        </div>
        <!-- v-model="model" -->
        <input
          :class="($slots.prepend || $slots.append) ? 'w-full focus:outline-none px-2' : errors ? `rounded-lg py-2 px-4 border text-inkNormal-simple border-kkiapayRed w-full focus:outline-none ${customClass}` : `rounded-lg py-2 px-4 border text-inkNormal-simple border-inputBorder focus:border-blueNormal w-full focus:outline-none ${customClass}`"
          v-bind="attributes"
          v-on="listeners"
          :type="type"
          :name="name"
          :readonly="readonly"
        />
        <div class="input-group-append" v-if="$slots.append">
          <slot name="append"></slot>
        </div>
      </div>
      <span v-if="errors" class="px-4 py-1 flex rounded-lg bg-errorBg space-x-1 text-sm items-center mt-2">
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.3335C4.32004 1.3335 1.33337 4.32016 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335ZM8.00004 8.66683C7.63337 8.66683 7.33337 8.36683 7.33337 8.00016V5.3335C7.33337 4.96683 7.63337 4.66683 8.00004 4.66683C8.36671 4.66683 8.66671 4.96683 8.66671 5.3335V8.00016C8.66671 8.36683 8.36671 8.66683 8.00004 8.66683ZM7.33337 10.0002V11.3335H8.66671V10.0002H7.33337Z" fill="black" />
                </mask>
                <g mask="url(#mask0)">
                    <rect width="16" height="16" fill="#DA1414" />
                </g>
            </svg>
          </span>
          <span class="text-kkiapayRed">{{ errors }}</span>
      </span>
    </div>
</template>

<script>
export default {
    props: {
        errors: null,
        customClass:String,
        label: String,
        name: String,
        value: {},
        readonly: {},
        type: {
            type: String,
            default: "text"
        },
        /* acceptNumberOnly: {
          type: Boolean,
          default: false
        },
        isNumberKey: {
          type: Function,
          default(evt) {
            var charCode = (evt.which) ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
            return true;
          }
        } */
    },
    data() {
        return {
            model: this.value || "",
            wrapperErrors: 'flex items-center space-x-1 w-full rounded-lg select-none h-10 sm:h-10 px-4 border text-inkNormal-simple border-kkiapayRed focus-within:border-blueNormal bg-white'
        }
    },
    methods: {
    changeValue(val) {
      console.log("change value", val);
      this.model = val;
    },

    setInitialValueForInput(value) {
      this.$emit("input ", value);
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.model = event.target.value;

          this.$emit("input", event.target.value);
        },
        focus: (event) => {
          this.$emit("focus", event);
        },
        blur: (event) => {
          this.$emit("blur", event);
        }
      };
    },

    attributes() {
      return {
        ...this.$attrs,
        value: this.value
      };
    },

    checkable() {
      return this.type === "radio" || this.type === "checkbox";
    },

    wapperClass() {
      const classes = ["bg-white flex items-center space-x-1 w-full rounded-lg select-none h-10 sm:h-10 px-4 pr-1 border text-inkNormal-simple border-inputBorder focus-within:border-blueNormal"];

      if (this.tagInput) {
        classes.push("min-h-10.5");
      } else {
        classes.push("h-10.5");
      }

      if (!this.checkable) {
        classes.push(
          "border",
          "mb-2",
          "focus-within:border-Tint-30",
          "border-btnBorder"
        );
      }
      return classes;
    }
  },

  created() {
    if (this.value && this.value != null) {
        this.setInitialValueForInput(this.value);
    }
  }
}
</script>

<style scoped>
input[type="radio"] {
  min-width: 15px;
}
</style>