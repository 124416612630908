<template>
  <div id="app" class="fontBni">
    <router-view/>
  </div>
</template>

<script>
import './assets/iconComponents'
export default {
  name: 'App'
}
</script>

<style lang="postcss">
.fontBni{
  font-family: 'HelveticaNeueCyr', sans-serif; 
}

@tailwind base;

@tailwind components;

@tailwind utilities;

</style>
