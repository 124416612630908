/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 9.5A1.5 1.5 0 015.5 8h21a1.5 1.5 0 010 3h-21A1.5 1.5 0 014 9.5zM4 16a1.5 1.5 0 011.5-1.5h21a1.5 1.5 0 010 3h-21A1.5 1.5 0 014 16zM4 22.5A1.5 1.5 0 015.5 21h21a1.5 1.5 0 010 3h-21A1.5 1.5 0 014 22.5z" _fill="#000"/>'
  }
})
