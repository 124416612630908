import Vue from 'vue'
import App from './App.vue'
import { router } from './plugins'
import store from './store'

import Modal from '@/components/modal/Modal'
import MInput from '@/components/globalComponents/MInput'
import MSelect from '@/components/globalComponents/MSelect'
import MFileInput from '@/components/globalComponents/MFileInput'
import MRadio from '@/components/globalComponents/MRadio'
import MButton from '@/components/globalComponents/MButton'
import BaseButton from '@/components/globalComponents/BaseButton'
import VTable from '@/components/globalComponents/VTable'
import LoaderCss from '@/components/LoaderCss'
import IconClose from '@/assets/icons/closeMod.svg'

import SvgIcon from 'vue-svgicon'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
const token = localStorage.getItem('token')
const header = { "Authorization": `Bearer ${token}` }
export const instanceApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: header
})

Vue.use(SvgIcon, {
    tagName: 'svgicon',
    isOriginalDefault: true
})
Vue.config.productionTip = false

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate'
import { required, email, min, max, numeric, confirmed, mimes } from 'vee-validate/dist/rules'
extend('email', {
    ...email,
    message: 'L\'adresse e-mail est invalide'
});
extend('confirmed', {
    ...confirmed,
    message: '{_field_} n\'est pas conforme'
});
extend('numeric', {
    ...numeric,
    message: '{_field_} doit contenir que des chiffres'
});
extend('mimes', {
    ...mimes,
    message: '{_field_} doit contenir que des images'
});
extend('min', {
    ...min,
    message: '{_field_} est invalide'
});
extend('max', {
    ...max,
    message: '{_field_} est invalide'
});
extend('required', {
    ...required,
    message: '{_field_} est requis(e)'
});

extend('upCase', { validate: value => value.match(/[A-Z]+/g) !== null, message: '{_field_} doit contenir au moins une majuscule' });
extend('oneNumber', { validate: value => value.match(/[0-9]+/g) !== null, message: '{_field_} doit contenir au moins un chiffre' });
extend('oneCharac', { validate: value => value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g) !== null, message: '{_field_} doit contenir au moins un caractère spécial' });


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


Vue.component("Modal", Modal);
Vue.component("MInput", MInput);
Vue.component("MSelect", MSelect);
Vue.component("MFileInput", MFileInput);
Vue.component("MRadio", MRadio);
Vue.component("VTable", VTable);
Vue.component("MButton", MButton);
Vue.component("BaseButton", BaseButton);
Vue.component("LoaderCss", LoaderCss);

Vue.component('IconClose', IconClose)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')