import axios from 'axios'
import User from '../models/user';
const state = {};

const getters = {}

const mutations = {}

const actions = {
    getAllUsers() {
        axios.get('/users/all?')
            .then((response) => {

                const users = response.data.data
                users.forEach(user => {
                    User.insert({ data: user })
                });
            })
            .catch(error => {
                console.log('getAll ', error);
            })
    }
}

export default {
    state,
    getters,
    mutations,
    actions

}